import Vue from 'vue';
import axios from 'axios';
import { Loading, Message } from 'element-ui';
import { getTokenInfo } from '@/utils/login/storage';
import { processEnv } from '@/utils/env';

// loading 配置文件
const options = {
	fullscreen: true,
	background: 'rgba(0,0,0,0.1)',
};
// loading 实例
let loadingInstance = null;

axios.defaults.headers.post['Content-Type'] =
	'application/x-www-form-urlencoded;charset=UTF-8';

let baseURL = processEnv.VUE_APP_API_ROOT;

const _axios = axios.create({
	baseURL: baseURL,
	timeout: 15000, // Timeout
	withCredentials: true, // Check cross-site Access-Control
});

_axios.interceptors.request.use(
	function (config) {
		config.headers.Authorization = getTokenInfo();
		if (!config.loadingHide) {
			loadingInstance = Loading.service(options);
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

// 校验拦截
const checkCode = data => {
	if (data && data.statusCode === 0) {
		return true;
	} else {
		return false;
	}
};

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		loadingInstance && loadingInstance.close();
		if (checkCode(response.data)) {
			return response.data;
		} else {
			Message.closeAll();
			Message.error(response.data.message);
			return Promise.reject(response);
		}
	},
	function (error) {
		loadingInstance && loadingInstance.close();
		if (error && error.response && error.response.status) {
			switch (error.response.status) {
				case 401:
					// 未登录
					Message.closeAll();
					Message.error(error.response.data.message);
					if (error.response.data.statusCode === 407) {
						// 未进行二次校验
						window.location.replace(`${window.location.origin}/login-middle`);
					} else {
						window.location.replace(`${window.location.origin}/login`);
					}
					break;
				case 403:
					// 无权限
					Message.closeAll();
					Message.error(error.response.data.message);
					// TODO 重新获取管理员权限信息
					break;
				case 404:
					// 接口不存在
					Message.closeAll();
					Message.error(error.response.data.message);
					break;
				case 405:
					// 接口请求方式错误
					Message.closeAll();
					Message.error(error.response.data.message);
					break;
				case 422:
					// 接口参数异常
					Message.closeAll();
					Message.error(error.response.data.message);
					break;
				default:
					// 其他错误
					Message.closeAll();
					Message.error(error.message);
					break;
			}
		} else {
			Message.closeAll();
			Message.error(error.message);
		}
		return Promise.reject(error);
	},
);

Plugin.install = function (Vue) {
	Vue.axios = _axios;
	window.axios = _axios;
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return _axios;
			},
		},
		$axios: {
			get() {
				return _axios;
			},
		},
	});
};

Vue.use(Plugin);

export default Plugin;
